import React from "react";
import styled from "styled-components";
//import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgHeroTab from "../../assets/nightshift/logo-color-no-bg.png";

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => `${theme.colors.black}`};
`;

const ImgRight = styled(Box)`
  position: relative;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 3em;
    left: auto;
    transform: translateX(25%);
    padding-left: 420px;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 position-static">
              <ImgRight>
                <img
                  src={imgHeroTab}
                  alt=""
                  className="img-fluid"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-delay="1000"
                  data-aos-once="true"
                />
              </ImgRight>
            </Col>
            <Col md="7" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" color="light">
                    Delivering Digital Outcome
                  </Title>
                  <Text mb={4} color="light" opacity={0.7}>
                    Offering high-specialization, high-quality DevSecOps consulting engagements, 
                    while leveraging strategic partnerships to foster and incubate innovative ideas. 
                    Our services engagements focus on holistic value between the application and 
                    operations layer of emerging Container Platform technologies, while our special 
                    projects solve niche, high-value IT problems in ways not commonly found in the marketplace.
                  </Text>
                  <div className="d-flex flex-column align-items-start pt-3">
                    <Button mb={2}><a href="/contact/">Contact</a></Button>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
