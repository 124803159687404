import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconSecurity from "../../assets/nightshift/security.png";
import iconAutomation from "../../assets/nightshift/automation.png";
import iconSRE from "../../assets/nightshift/sre.png";

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="PLATFORM IMPLEMENTATION ENGINEERING" iconImage={iconSecurity}>
              Conquer Kubernetes. Our unique way of working lets you choose your outcome, pick your integrations, 
              and our tested-daily IAC offerings do the rest. From network options that communicate directly 
              with your existing datacenter investment to high-performance eBPF overlays, you pick options you want, 
              we assemble and deliver it for you.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="AUTOMATION AND PIPELINE DEVELOPMENT (DEVOPS)" iconImage={iconAutomation}>
              We provide delivery pipelines purpose-built to both reward ideas and ideation, and enforce security, 
              availability, and maturity of production infrastructure, that dynamically self-adjust 
              in context to reward the current stage of the software development lifecycle.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="DELIVERING DIGITAL TRANSFORMATION" iconImage={iconSRE}>
              Going cloud-native is harder than simply building a container image. 
              If your challenge falls into this category, NightShift Services can help you 
              navigate the journey and avoid common pitfalls and mistakes that become costly and difficult changes later.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
