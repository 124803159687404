import React from "react";
import Hero from "../sections/nightshift/Hero";
import Clients from "../sections/nightshift/Clients";
import Feature from "../sections/nightshift/Feature";
import Content1 from "../sections/nightshift/Content1";
import Content2 from "../sections/nightshift/Content2";
import CTA from "../sections/nightshift/CTA";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";


const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>NightShift Solutions | Kubernetes Consulting & Digital Transformation the Right Way.</title>
      </Helmet>
      <PageWrapper footerDark>
        <Hero />
        <Content1 />
        <Content2 />
        <Feature />
        <Clients />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
