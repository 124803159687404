import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconCloudNative from "../../assets/nightshift/cloud-native.png";
import iconAWS from "../../assets/nightshift/AWS.png";
import imgStaffing from "../../assets/nightshift/feature-staffing.png";

const iconStyle = {
  width: '2em',
}

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" style={iconStyle} />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 80%;
  }
  @media ${device.xl} {
    margin-left: 50px;
    max-width: 80% !important;
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgStaffing} alt="World Class Staffing" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <Title>
                Value-Oriented Project Delivery
              </Title>
              <Text>
                Time is of the essence to tackle real and meaningful challenges across science, 
                commerce, government, and innovation alike. NightShift Solutions ensures the 
                underlying compute infrastructure and automated processes for demanding environments 
                is not a source of frustration, delay, or financial waste, addressing the shortage 
                and competition for delivery talent for the Digital Enterprise by encoding desired outcomes in advance.
              </Text>
              <br />
              <Text>
                Our process reconciles every stage of an engagement back to the value it's designed to deliver. 
              </Text>
              <Box mt={4} pt={3}>
                <ContentCard
                  title="Platform Engineering"
                  iconImage={iconCloudNative}
                  mb={4}
                >
                    Modern compute environments start with a Container Platform, but the journey 
                    to Digital Transformation is far from over. Our Platform Implementation 
                    solutions and services offer Day-2 capabilities on Day-0.
                </ContentCard>
                <ContentCard
                  title="Pipeline Development & CI/CD"
                  iconImage={iconAWS}
                >
                  NightShift Solutions pioneers the development of information logistics pipelines 
                  that dynamically adjust behavior to maximize feedback and value to developers, 
                  transparently and automatically increasing security and maturity requirements where it matters most.
                   NightShift Services can help you gain the most out of your CI/CD journey.
                </ContentCard>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
